import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { ButtonGroup, ToggleButton, Container } from "react-bootstrap"
import Axios from "axios"

const Main = () => {
  const [host, setHost] = useState([])

  useEffect(() => {
    const getData = async () => {
      await Axios.get(`https://api.puntohost.com/2/hosting.php`).then(
        response => {
          const result = response.data
          setHost(result)
        }
      )
    }
    getData()
  }, [])
  const [currencyValue, setCurrencyValue] = useState("1")

  const currencies = [
    { name: "Dólares", value: "1" },
    { name: "Bolívares", value: "2" },
  ]

  return (
    <Layout>
      <Seo
        title="Web Hosting"
        description="Web Hosting ideal para sitios personales y empresariales. Incluye cPanel, Softaculous, Cloudflare, Selector php y Certificado SSL gratis"
      />
      <div className="h-hosting">
        <div className="text-center pt-6 pb-5">
          <Container>
            <h1>Hosting rápido y seguro creado para tí.</h1>
            <p>Diseñado para una fácil gestión de su sitio web</p>
          </Container>

          <ButtonGroup
            data-toggle="buttons"
            className="btn-group-toggle btn-group"
            role="group"
            aria-label="Cambio de moneda"
          >
            {currencies.map((currency, idx) => (
              <ToggleButton
                key={idx}
                id={`currency-${idx}`}
                type="radio"
                variant="dark"
                name="currency"
                value={currency.value}
                checked={currencyValue === currency.value}
                onChange={e => setCurrencyValue(e.currentTarget.value)}
              >
                {currency.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </div>

        <Container className="text-right cc"></Container>
      </div>

      <section id="pricing" className="container pb-4">
        {currencyValue === "1" ? (
          <>
            <div className="row">
              {host.map((data, ...index) => (
                <div key={index} className="col-md-6 col-lg-4 single-pricing-2">
                  <div className="inner">
                    <div className="title">
                      <p>{data.name.split(" - ")[1]}</p>
                    </div>
                    <div className="price">
                      <p>
                        <span className="discount">En oferta: Ahorra 20%</span>
                        <br />
                        <span className="prefix">{data.usd.prefix}</span>
                        <span>
                          {(
                            data.usd.price.annually -
                            data.usd.price.annually * 0.2
                          ).toFixed(2)}
                        </span>
                        <span className="mes">/año</span>
                        <p className="renew text-muted">
                          {data.usd.prefix}
                          {data.usd.price.annually}/año cuando renuevas
                        </p>
                      </p>
                    </div>

                    <div className="btn-buy">
                      <Link
                        id={`${data.name}-${data.usd.suffix}`}
                        className="btn btn-primary btn--semi-large"
                        to={`${data.usd.url}`}
                      >
                        Contratar
                      </Link>
                    </div>

                    <ul
                      dangerouslySetInnerHTML={{
                        __html: data.description,
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <div className="row">
              {host.map((data, ...index) => (
                <div key={index} className="col-md-6 col-lg-4 single-pricing-2">
                  <div className="inner">
                    <div className="title">
                      <p>{data.name.split(" - ")[1]}</p>
                    </div>
                    <div className="price">
                      <span className="discount">En oferta: Ahorra 20%</span>
                      <br />
                      <span className="prefix">{data.ves.prefix}</span>
                      <span>
                        {(
                          data.ves.price.annually -
                          data.ves.price.annually * 0.2
                        ).toFixed(0)}
                      </span>
                      <span className="mes">/año</span>
                      <p className="renew text-muted">
                        {data.ves.prefix}
                        {(data.ves.price.annually / 1).toFixed(0)}/año cuando
                        renuevas
                      </p>
                    </div>

                    <div className="btn-buy">
                      <Link
                        id={`${data.name}-${data.ves.suffix}`}
                        className="btn btn-primary btn--semi-large"
                        to={`${data.ves.url}`}
                      >
                        Contratar
                      </Link>
                    </div>

                    <ul
                      dangerouslySetInnerHTML={{
                        __html: data.description,
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </section>

      <div className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Las mejores caracteristicas al mejor precio.</h2>
            <p>
              Funciones avanzadas de alojamiento web que impulsan los sitios web
              más rápidos
            </p>
          </div>
        </div>
      </div>

      <section id="features" className="pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6 pb-5">
              <i className="icon-22 icon-2x"></i>
              <p className="f-title">Certificado SSL GRATIS</p>
              <p>
                Proporcionamos certificados SSL gratuitos "Let's Encrypt" para
                proteger su sitio con HTTPS, que cifra el tráfico y mejora el
                SEO al instante con Google.
              </p>
            </div>
            <div className="col-lg-4 col-sm-6 pb-5">
              <i className="icon-80 icon-2x"></i>
              <p className="f-title">Panel fácil de usar</p>
              <p>
                Cree fácilmente nuevas cuentas de correo electrónico,
                certificiados SSL, bases de datos y administre sus archivos.
              </p>
            </div>
            <div className="col-lg-4 col-sm-6 pb-5">
              <i className="icon-85 icon-2x"></i>
              <p className="f-title">Potente instalador de 1-click</p>
              <p>
                Ahorre tiempo y dinero, instale WordPress u otra aplicación
                preensamblada con un click.
              </p>
            </div>
            <div className="col-lg-4 col-sm-6 pb-5">
              <i className="icon-55 icon-2x"></i>
              <p className="f-title">Migración de sitio web</p>
              <p>
                Transfiere tu sitio web sin preocupaciones con la ayuda de
                nuestros expertos en migración.
              </p>
            </div>
            <div className="col-lg-4 col-sm-6 pb-5">
              <i className="icon-3 icon-2x"></i>
              <p className="f-title">Correos electrónicos</p>
              <p>
                Luzca profesional con direcciones de correo electrónico
                personalizada para su dominio.
              </p>
            </div>
            <div className="col-lg-4 col-sm-6 pb-5">
              <i className="icon-63 icon-2x"></i>
              <p className="f-title">Soporte calificado</p>
              <p>
                Envie un ticket o inicie un chat en vivo (9:00AM - 6:00PM UTC-3)
                - Estamos aquí para ayudarle.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Main
